<template>
  <client-only>
    <nuxt />
  </client-only>
</template>

<script>

export default {
  name: 'DefaultLayout'
}

</script>
<style>
#__nuxt,
#__layout {
  height: 100%;
}
</style>
