import { getTaxDetails, toLocalStorage } from '@/utils'

const switchError = (code, option = {}) => {
  let message = 'Something thing went wrong'
  const _self = window.$nuxt
  switch (code) {
    case 403: {
      message = option['403'] || 'Your don\'t have right permission or your access has been revoked'
      const temp = _self.$f7.$('.page-current > .navbar | .link.panel-open').length > 0
      if (temp) {
        _self.$f7.views.main.router.navigate('/')
      }
      break
    }
    case 401: {
      message = option['401'] || 'Session has been expired. Please login.'
      _self.$f7.views.main.router.navigate('/login')
      _self.$f7.views.main.router.clearPreviousHistory()
      _self.$f7.panel.close()
      _self.$store.dispatch('auth/logout')
      break
    }
  }
  return message
}

export const handleError = (error, options = {}) => {
  let message = 'Something went wrong'
  if (error && error.response && error.response.status <= 500) {
    if (error.response.data.data) {
      error = error.response.data.data
    } else {
      error = error.response.data || error.response
    }
    if (error.errors) {
      const errors = error.errors.errors || error.errors
      if (errors.code) {
        message = switchError(errors.code, options)
      } else if (Object.keys(errors).length) {
        // handling 401 and 422
        message = Object.values(errors)[0]
      }
    } else if (error.message) {
      message = error.message
    } else {
      message = switchError(error.status, options)
    }
  } else if (window.$nuxt.isOffline) {
    message = 'Please check your network connection status.'
  }
  switch (options.dialog) {
    case 'alert': {
      window.$nuxt.$f7.dialog.close()
      window.$nuxt.$f7.dialog.alert(message, null)
      break
    }
    case 'skip': {
      break
    }
    default: {
      window.$nuxt.$f7.toast
        .create({
          text: message,
          closeButton: true,
          closeTimeout: 8000
        })
        .open()
      break
    }
  }
}

function _convertFieldFromOriginal (fields) {
  fields = { ...fields }
  const includeKeys = Object.keys(fields)
  for (let index = 0; index < includeKeys.length; index++) {
    const oKey = includeKeys[index]
    const element = fields[oKey]
    fields[oKey] = element.return ? element.return : element
  }
  return fields
}

function _convertFieldFromOriginalById (fields) {
  fields = { ...fields }
  const includeKeys = Object.keys(fields)
  for (let index = 0; index < includeKeys.length; index++) {
    const oKey = includeKeys[index]
    const element = fields[oKey]
    fields[oKey] = element.return ? element.return : element.id
  }
  return fields
}

export const convertFieldFromOriginal = _convertFieldFromOriginal
export const convertFieldFromOriginalById = _convertFieldFromOriginalById

export const findDuplicateByID = (items, index) => {
  index = index.return || index
  let returnIndex = -1
  if (items.length > 0) {
    returnIndex = items.findIndex((content) => {
      const element = content.return || (content.item ? content.item.id : content.id)
      return element === index
    })
  }
  return returnIndex
}

export const findDuplicateByItemID = (items, index) => {
  index = index.return || index
  let returnIndex = -1
  if (items.length > 0) {
    returnIndex = items.findIndex((content) => {
      const element = content.return || content.item_id || content.item.id
      return element === index
    })
  }
  return returnIndex
}

export const roundOff = (value, fixed = 2) => {
  if (!value) { return 0 }
  return parseFloat(value.toFixed(fixed))
}

/*
 * This function used to calculate purchase order only.
 */
export const calculateTax = (baseItem) => {
  const purchaseOrderSchema = {
    total_qty: 0,
    sub_total: 0,
    total_tax: 0,
    total: 0,
    total_shipping_cost: 0,
    items: [],
    total_discount: 0
  }
  for (let index = 0; index < baseItem.length; index++) {
    const item = baseItem[index]
    // eslint-disable-next-line no-unused-vars
    let baseItemSchema = {
      item_id: null,
      item_inventory_id: null,
      item_details: {},
      qty: 0,
      total: 0,
      unit_cost: 0,
      selling_price: 0,
      tax: 0,
      taxes: []
    }

    baseItemSchema.item_details = {
      item_name: (item.item ? item.item.name : false) || item.item_id.name
    }

    baseItemSchema.item_id = (item.item ? item.item.id : false) || item.item_id.return

    baseItemSchema.qty = item.qty
    baseItemSchema.item_inventory_id = item.item_inventory_id
    baseItemSchema.unit_cost = item.unit_cost
    baseItemSchema.selling_price = item.selling_price

    const total = parseFloat((item.unit_cost * item.qty))
    // baseItemSchema.total = roundOff(total)
    // updating quantity
    purchaseOrderSchema.total_qty += item.qty
    const temp = getTaxDetails(total, item.taxes)

    baseItemSchema.total = temp.amount
    baseItemSchema.taxes = temp.taxes
    baseItemSchema.tax = temp.tax
    baseItem[index].temp_tax = temp.tax

    purchaseOrderSchema.total_tax += baseItemSchema.tax
    purchaseOrderSchema.sub_total += total

    if (item.discount_value || item.discount_type) {
      let discount
      if (item.discount_type === 'fixed') {
        discount = item.discount_value
      } else {
        discount = baseItemSchema.total * (item.discount_value / 100)
      }
      baseItemSchema.discount = discount
      purchaseOrderSchema.total_discount += discount

      baseItemSchema.total = baseItemSchema.total - discount
    }

    if (item.custom_attributes && Object.keys(item.custom_attributes).length > 0) {
      baseItemSchema.custom_attributes = item.custom_attributes
    }

    if (item.additionItemField && Object.keys(item.additionItemField).length > 0) {
      baseItemSchema = {
        ...baseItemSchema,
        ...item.additionItemField
      }
    }
    baseItemSchema.total = roundOff(baseItemSchema.total)
    baseItemSchema.tax = roundOff(baseItemSchema.tax)

    if (baseItemSchema.discount) {
      baseItemSchema.discount = roundOff(baseItemSchema.discount)
    }
    purchaseOrderSchema.items.push(baseItemSchema)
  }
  // calculate grand total =  sub-total + total tax + total discount
  purchaseOrderSchema.total = (purchaseOrderSchema.sub_total + purchaseOrderSchema.total_tax)

  if (purchaseOrderSchema.total_discount) {
    purchaseOrderSchema.total -= purchaseOrderSchema.total_discount
  }

  purchaseOrderSchema.total_discount = roundOff(purchaseOrderSchema.total_discount)
  purchaseOrderSchema.total_tax = roundOff(purchaseOrderSchema.total_tax)
  purchaseOrderSchema.sub_total = roundOff(purchaseOrderSchema.sub_total)
  purchaseOrderSchema.total = roundOff(purchaseOrderSchema.total)
  return purchaseOrderSchema
}

class ACLControl {
  constructor (name) {
    this.policyType = toLocalStorage('userPolicy')[name]
  }

  aclCustom (acl) {
    let accessStatus = toLocalStorage('isSuperAdmin')
    accessStatus = accessStatus || (
      this.policyType ? this.policyType.includes(acl) : false
    )
    return accessStatus
  }

  aclCreate () {
    return this.aclCustom('create')
  }

  aclView () {
    return this.aclCustom('view')
  }

  aclDelete () {
    return this.aclCustom('delete')
  }

  aclUpdate () {
    return this.aclCustom('update')
  }
}
export const ACL = (name) => {
  return new ACLControl(name)
}

export const getMetricStdNameOnly = (unitMeasureName = '') => {
  unitMeasureName = unitMeasureName.toLowerCase()
  switch (unitMeasureName) {
    case 'count': {
      return ''
    }
    case 'length': {
      return 'Mtr'
    }
    case 'weight': {
      return 'Kg'
    }
    case 'volume': {
      return 'Ltr'
    }
    case 'basket': {
      return 'Kg'
    }
    default: {
      return ''
    }
  }
}

export const getMetricStdName = (value, unitMeasureName) => {
  if (!value) {
    value = 0
  }
  value = Number(value)
  return `${value.toFixed(2)} ${getMetricStdNameOnly(unitMeasureName)}`
}
