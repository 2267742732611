/* eslint-disable */
import Vue from 'vue'

import { extend, localize, ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  required, email, alpha, min_value, max_value, numeric, alpha_num,
  alpha_spaces
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'

extend('email', email)
extend('required', required)
extend('alpha', alpha)
extend('alpha_num', alpha_num)
extend('min_value', min_value)
extend('max_value', max_value)
extend('numeric', numeric)
extend('alpha_spaces', alpha_spaces)
extend('must_not_be_eq_with', {
  params: [{ name: 'other', isTarget: true }, { name: 'otherFieldName' }, { name: 'message' }],
  validate: (value, { other } = {}) => {
    value = value.return || value
    other = other.return || other
    return value !== other
  },
  message: (field, { otherFieldName, message }) => {
    return message || `'${field}' must not equal to '${otherFieldName}'`
  }
})

extend('decimal', {
  params: ['decimals', 'separator'],
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (Number(decimals) === 0) {
      return /^-?\d*$/.test(value)
    }

    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

    if (!regex.test(value)) {
      return false
    }

    const parsedValue = parseFloat(value)

    // eslint-disable-next-line
    return parsedValue === parsedValue;
  },
  message: (field, { decimals }) => {
    return `The ${field} field allow only numeric or ${decimals} decimal points`
  }
})

localize({
  en
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
