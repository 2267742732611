export const strict = false

export const mutations = {
  showLoader () {
    window.$nuxt.$f7.preloader.show()
  },
  closeLoader () {
    window.$nuxt.$f7.preloader.hide()
  },
  fakeLoader (state, { delay } = { delay: 500 }) {
    window.$nuxt.$f7.preloader.show()
    setTimeout(() => {
      window.$nuxt.$f7.preloader.hide()
    }, delay)
  }
}

export const actions = {
  nuxtServerInit ({ commit, dispatch }, { req }) {
    if (process.server) {
      if (req.session && !req.session.authorization) {
        commit('auth/setAuth', 0, { root: true })
        dispatch('auth/setLoginURL', { root: true })
      }
    }
  }
}
