import moment from 'moment'
export { getMetricStdName, getMetricStdNameOnly } from '@/pages/erp/utils'

export const toFixed = (value, arg = 2) => {
  if (!value || !Number(value)) { return '0.00' }
  return value.toFixed(arg)
}

export const weight = (value) => {
  if (value !== 0 || !value) { return value }
  // value = parseFloat(value)
  return value
}

export const formatDate = (value) => {
  if (!value) { return '--' }
  return moment.utc(value).local().format('DD/MM/YYYY')
}

export const customFormate = (value, formate) => {
  if (!value) { return '--' }
  return moment.utc(value).local().format(formate)
}

export const numericShortForm = (value) => {
  if (!Number(value)) { return '0.00' }
  const toString = `${value}`
  if (value >= 100000000) { // 10 Crore
    return `${toString.substring(0, 2)}C+`
  } else if (value >= 10000000) { // 1 Crore
    return `${toString.substring(0, 1)}C+`
  } else if (value >= 1000000) { // 10 Lakh
    return `${toString.substring(0, 2)}L+`
  } else if (value >= 100000) { // 1 Lakh
    return `${toString.substring(0, 1)}L+`
  } else {
    return new Intl.NumberFormat('en-IN').format(value)
  }
}
