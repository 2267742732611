export default ({ app }, inject) => {
  const routes = app.router.options.routes.reduce((routes, route) => {
    routes.push({
      path: route.path,
      name: route.name,
      async (routeTo, routeFrom, resolve) {
        // eslint-disable-next-line arrow-parens
        route.component().then((c) => resolve({ component: c }))
      }
    })
    return routes
  }, [])
  inject('routes', routes)
}
