export const state = () => {
  return {
    backLinkActive: false
  }
}

export const getters = {
  backLinkStatus (state) {
    return state.backLinkActive
  }
}

export const mutations = {
  toggleBackLink (state) {
    state.backLinkActive = !state.backLinkActive
  },
  scrollToByQuery (state, { querySelection }) {
    document.querySelector(querySelection).scrollIntoView({
      behavior: 'smooth'
    })
  }
}
