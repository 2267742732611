
function initData () {
  return {
    common: {}
  }
}

export const state = () => {
  return initData()
}

export const mutations = {
  resetAllFilters (state) {
    state.common = {}
  }
}
