import { calculateTax as _calculateTax } from '@/pages/erp/utils'

export const state = () => {
  return {
    addedItemBase: {},
    originalItemBase: [],
    orderContent: {},
    selectTax: [],
    createOrder: {
      vendor_id: null,
      location_id: null,
      delivery_date: null
    },
    procurementOrder: {
      from_location_id: null,
      to_location_id: null
    },
    createTransferOrder: {
      from_location_id: null,
      to_location_id: null,
      assigned_to: null
    }
  }
}

export const getters = {
  itemBase (state) {
    return state.originalItemBase
  },
  getPurchaseOrder (state) {
    return state.addedItemBase
  },
  getTax (state) {
    return state.selectTax
  },
  getOrderSchema (state) {
    return state.createOrder
  }
}

export const mutations = {
  resetTax (state) {
    state.selectTax = []
  },
  removeByIndexTax (state, index) {
    state.selectTax.splice(index, 1)
  },
  setTax (state, tax) {
    const cached = {}
    tax.map((item) => { cached[item.id] = item })
    state.selectTax = Object.values(cached)
  },
  updateKey (state, { key, value }) {
    state.orderContent[key] = value
  },
  /*
   * @param item - object with details of the new base line item.
   */
  addItemBase (state, { item, editStatus }) {
    let id
    if (state.originalItemBase.length > 0) {
      if (editStatus) {
        id = state.originalItemBase.findIndex((x) => {
          return (x.item_id ? x.item_id.return : x.item.id) === item.item_id.return
        })
      } else {
        id = state.originalItemBase.findIndex((x) => {
          return x.item_id.return === item.item_id.return
        })
      }
    }
    if (id !== null && id >= 0) {
      state.originalItemBase[id] = item
    } else {
      state.originalItemBase.push(item)
    }
  },
  removeItemBaseById (state, id) {
    state.originalItemBase.splice(id, 1)
  },
  replaceItemBaseById (state, { item, id = null }) {
    state.originalItemBase[id] = item
  },
  restAll (state) {
    state.addedItemBase = {}
    state.originalItemBase = []
    state.orderContent = {}
    state.selectTax = []
    state.createOrder = {}
    state.procurementOrder = {
      from_location_id: null,
      to_location_id: null
    }
    state.createTransferOrder = {
      from_location_id: null,
      to_location_id: null,
      assigned_to: null
    }
  },
  calculateTax (state, baseItem) {
    state.addedItemBase = _calculateTax(baseItem)
  }
}

export const actions = {
  addItem ({ state, commit }, { item, id = null, editStatus }) {
    if (id || id === 0) {
      commit('replaceItemBaseById', { item, id, editStatus })
    } else {
      commit('addItemBase', { item, editStatus })
    }
    commit('calculateTax', state.originalItemBase)
  },
  updateItem ({ state, commit }) {
    commit('calculateTax', state.originalItemBase)
  }
}
