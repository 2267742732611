import { toLocalStorage } from '@/utils'

function setModelPath (context, value) {
  return {
    main: '/erp',
    left: '/erp'
  }
}

export const state = () => {
  return {
    isAuth: false,
    error: null,
    packageName: null,
    baseURL: null,
    packageId: null,
    userPolicy: null,
    userName: null,
    userEmail: null
  }
}

export const getters = {
  getAuth (state) {
    const auth = Boolean(Number(localStorage.getItem('auth')))
    if (auth) {
      state.isAuth = true
    } else {
      state.isAuth = false
    }
    return state.isAuth
  },
  getURL (state, getters) {
    if (getters.getAuth) {
      return state.baseURL ? state.baseURL : setModelPath(state, getters.getAuthPackage)
    } else {
      return {
        main: '/login',
        left: null
      }
    }
  },
  getAuthPackage (state, getters) {
    if (getters.getAuth) {
      return state.packageName ? state.packageName : toLocalStorage('package')
    }
  },
  getProfile (state) {
    return {
      name: state.userName ? state.userName : toLocalStorage('userName'),
      email: state.userEmail ? state.userEmail : toLocalStorage('userEmail')
    }
  },
  getLocations (state) {
    return state.locations
  },
  getUserPolicy (state) {
    return state.userPolicy ? state.userPolicy : toLocalStorage('userPolicy')
  }
}

export const mutations = {
  setAuth (state, authStatus) {
    state.isAuth = authStatus
    if (process.client) {
      localStorage.setItem('auth', Number(authStatus))
    }
  },
  setPackageId (state, value) {
    state.packageId = value
  },
  logout () {
    localStorage.clear()
  },
  setLocations (state, data) {
    state.locations = data
  },
  setLocationId (state, id) {
    state.locationId = id
  },
  updateUserPolicy (state, policy) {
    state.userPolicy = policy
  }
}

export const actions = {
  login ({ commit, state, dispatch }, payload) {
    const url = '/api/login'
    return this.$axios.$post(url, payload)
      .then((res) => {
        if (res.data.info.admin_user_type === 'not_allowed') {
          window.$nuxt.$f7.dialog.alert('You don\'t have required permission to use Admin app', null)
          dispatch('setLoginURL')
        } else {
          commit('setAuth', 1)
          const data = res.data.info
          state.packageName = data.admin_user_type
          state.userEmail = data.email
          state.userName = data.userName
          if (this.$sentry) {
            this.$sentry.configureScope((scope) => {
              scope.setTag({
                user_name: data.username,
                user_type: data.user_type,
                module_type: state.packageName
              })
            })
          }
          commit('setPackageId', state.packageName)
          dispatch('setAuthURL')
          toLocalStorage('userPolicy', res.data.policy)
          state.userPolicy = res.data.policy
          toLocalStorage('package', state.packageName)
          state.baseURL = setModelPath(state, state.packageName)

          toLocalStorage('deviceID', res.data.device.id)
          toLocalStorage('userID', data.id)
          toLocalStorage('isSuperAdmin', data.is_super_admin)
          toLocalStorage('profilePic', data.profile_pic)
          toLocalStorage('userName', data.username)
          toLocalStorage('userEmail', data.email)
          toLocalStorage('userRealName', data.name)
          toLocalStorage('userMobileNumber', data.mobile)
          toLocalStorage('settings', data.settings)
        }
      })
  },
  setLoginURL (context) {
    context.baseURL = {
      main: '/login',
      left: null
    }
  },
  setAuthURL (context) {
    if (context.getters.getAuth) {
      context.state.baseURL = setModelPath(context, context.getters.getAuthPackage)
    } else {
      context.dispatch('setLoginURL')
    }
  },
  setLocations (context, locations) {
    context.commit('setLocations', locations)
  },
  setLocationId (context, id) {
    context.commit('setLocationId', id)
  },
  logout ({ commit }) {
    return this.$axios.post('/api/logout').then(() => {
      window.$nuxt.$f7.panel.close()
      window.$nuxt.$f7.views.main.router.navigate('/login/')
      commit('logout')
      commit('erp/filter/resetAllFilters', null, { root: true })
      this.$bridge.clearLocalStorage()
      commit('setAuth', 0)
    })
  },
  setUserPolicy (context, policy) {
    context.commit('updateUserPolicy', policy)
  }
}
