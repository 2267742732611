import Vue from 'vue'
import { roundOff } from '@/pages/erp/utils'

import {
  formatDate, weight, toFixed, customFormate, numericShortForm, getMetricStdName,
  getMetricStdNameOnly
} from './filters'

Vue.filter('formatDate', formatDate)
Vue.filter('weight', weight)
Vue.filter('toFixed', toFixed)
Vue.filter('customFormate', customFormate)
Vue.filter('numericShortForm', numericShortForm)
Vue.filter('getMetricStdName', getMetricStdName)
Vue.filter('getMetricStdNameOnly', getMetricStdNameOnly)
Vue.filter('roundOff', roundOff)
