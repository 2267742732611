export const state = () => {
  return {
    address: {}
  }
}

export const getters = {
  getAddress (state) {
    return state.address
  }
}

export const mutations = {
  setAddress (state, { key, address }) {
    state.address[key] = address
  },

  removeAddress (state, { key }) {
    state.address[key] = null
  },
  removeAllAddress (state) {
    state.address = {}
  }
}
