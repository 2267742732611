
export const getTaxDetails = (amount, taxes) => {
  let inclusivePercent = 0
  let exclusivePercent = 0
  const taxesInclusive = []
  const taxesExclusive = []
  for (let i = 0; i < taxes.length; i++) {
    const tax = taxes[i]
    if (tax.inclusion_type === 'inclusive') {
      inclusivePercent = inclusivePercent + parseFloat(tax.rate)
    } else {
      exclusivePercent = exclusivePercent + parseFloat(tax.rate)
    }
  }
  const price = amount - amount * (inclusivePercent / (100 + inclusivePercent))
  for (let i = 0; i < taxes.length; i++) {
    const tax = taxes[i]
    if (tax.inclusion_type === 'inclusive') {
      taxesInclusive.push({
        tax_id: tax.id,
        tax_name: tax.name,
        tax_rate: tax.rate,
        tax_amount: price * (parseFloat(tax.rate) / 100),
        amount: price
      })
    } else {
      taxesExclusive.push({
        tax_id: tax.id,
        tax_name: tax.name,
        tax_rate: tax.rate,
        tax_amount: amount * (parseFloat(tax.rate) / 100),
        amount
      })
    }
  }
  if (exclusivePercent > 0) {
    return {
      amount,
      tax: amount * (exclusivePercent / 100),
      type: 'additive',
      taxes: taxesExclusive
    }
  } else if (inclusivePercent > 0) {
    return {
      amount: price,
      tax: amount * (inclusivePercent / (100 + inclusivePercent)),
      type: 'inclusive',
      taxes: taxesInclusive
    }
  } else {
    return {
      amount,
      tax: 0,
      type: 'additive',
      taxes: []
    }
  }
}

export const toFixedFloat = (value, persist = 2, base = 10) => {
  const pow = (base || 10) ** persist
  return parseFloat(Math.round(value * pow) / pow)
}

export const toLocalStorage = (key, value = null) => {
  if (value) {
    value = JSON.stringify({ [key]: value })
    localStorage.setItem(key, value)
  } else {
    let temp = localStorage.getItem(key)
    temp = JSON.parse(temp)
    if (!temp) { return false }
    return temp[key]
  }
}

export const fireAndForget = (key) => {
  const temp = toLocalStorage(key)
  if (!temp) {
    return false
  }
  localStorage.removeItem(key)
  return temp
}

export const ObjectToParam = (value) => {
  return window.$nuxt.$f7.utils.serializeObject(value)
}
