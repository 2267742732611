
export const state = () => {
  return {
    result: {
      orderItem: [],
      orderItemsTemp: []
    }, // result of the PopSearch value to server.
    original: {}
  }
}

export const mutations = {
  updateResult (state, { value, key }) {
    state.result[key] = value
  },
  updateResultWithOriginal (state, { key, value, original }) {
    state.result[key] = value
    state.original[key] = original
    console.log('original key are:', Object.keys(state.original))
  },
  reset (state) {
    state.result = {
      orderItem: [],
      orderItemsTemp: []
    }
    state.original = {}
  },
  remove (state, key) {
    if (state.result[key]) {
      delete state.result[key]
    }
  }
}
