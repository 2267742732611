import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ba4a95d8 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _62876ead = () => interopDefault(import('../pages/erp/index.vue' /* webpackChunkName: "pages/erp/index" */))
const _03ac52d0 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _7514bd32 = () => interopDefault(import('../pages/erp/dashboard/index.vue' /* webpackChunkName: "pages/erp/dashboard/index" */))
const _56d69e30 = () => interopDefault(import('../pages/erp/mixins.js' /* webpackChunkName: "pages/erp/mixins" */))
const _1e9ebbc6 = () => interopDefault(import('../pages/erp/orders/index.vue' /* webpackChunkName: "pages/erp/orders/index" */))
const _a6d977e2 = () => interopDefault(import('../pages/erp/PopUpSearch.vue' /* webpackChunkName: "pages/erp/PopUpSearch" */))
const _acafa40e = () => interopDefault(import('../pages/erp/purchase/index.vue' /* webpackChunkName: "pages/erp/purchase/index" */))
const _fcab84a8 = () => interopDefault(import('../pages/erp/report/index.vue' /* webpackChunkName: "pages/erp/report/index" */))
const _2809bf03 = () => interopDefault(import('../pages/erp/utils.js' /* webpackChunkName: "pages/erp/utils" */))
const _81696a6e = () => interopDefault(import('../pages/erp/vendors/index.vue' /* webpackChunkName: "pages/erp/vendors/index" */))
const _b1ee7f70 = () => interopDefault(import('../pages/erp/dashboard/filter.vue' /* webpackChunkName: "pages/erp/dashboard/filter" */))
const _40fb9554 = () => interopDefault(import('../pages/erp/items/adjustment/index.vue' /* webpackChunkName: "pages/erp/items/adjustment/index" */))
const _620846eb = () => interopDefault(import('../pages/erp/items/inventory/index.vue' /* webpackChunkName: "pages/erp/items/inventory/index" */))
const _5e0ef70c = () => interopDefault(import('../pages/erp/items/item/index.vue' /* webpackChunkName: "pages/erp/items/item/index" */))
const _489ce09c = () => interopDefault(import('../pages/erp/items/location/index.vue' /* webpackChunkName: "pages/erp/items/location/index" */))
const _84929dd4 = () => interopDefault(import('../pages/erp/orders/detail.vue' /* webpackChunkName: "pages/erp/orders/detail" */))
const _c22d0e86 = () => interopDefault(import('../pages/erp/orders/filter.vue' /* webpackChunkName: "pages/erp/orders/filter" */))
const _3ebc239b = () => interopDefault(import('../pages/erp/purchase/AddItem.vue' /* webpackChunkName: "pages/erp/purchase/AddItem" */))
const _29e3b805 = () => interopDefault(import('../pages/erp/purchase/Create.vue' /* webpackChunkName: "pages/erp/purchase/Create" */))
const _23b0a0ba = () => interopDefault(import('../pages/erp/purchase/detail.vue' /* webpackChunkName: "pages/erp/purchase/detail" */))
const _75b7935a = () => interopDefault(import('../pages/erp/purchase/Edit.vue' /* webpackChunkName: "pages/erp/purchase/Edit" */))
const _04e36861 = () => interopDefault(import('../pages/erp/purchase/filter.vue' /* webpackChunkName: "pages/erp/purchase/filter" */))
const _4b24ff9b = () => interopDefault(import('../pages/erp/purchase/GRNDetail.vue' /* webpackChunkName: "pages/erp/purchase/GRNDetail" */))
const _0184ba18 = () => interopDefault(import('../pages/erp/purchase/Invoice.vue' /* webpackChunkName: "pages/erp/purchase/Invoice" */))
const _52630566 = () => interopDefault(import('../pages/erp/purchase/Payment.vue' /* webpackChunkName: "pages/erp/purchase/Payment" */))
const _f4f8828c = () => interopDefault(import('../pages/erp/purchase/Procurement/index.vue' /* webpackChunkName: "pages/erp/purchase/Procurement/index" */))
const _39de85ea = () => interopDefault(import('../pages/erp/purchase/SearchTax.vue' /* webpackChunkName: "pages/erp/purchase/SearchTax" */))
const _bcde22a6 = () => interopDefault(import('../pages/erp/purchase/TransferOrder/index.vue' /* webpackChunkName: "pages/erp/purchase/TransferOrder/index" */))
const _6eb79596 = () => interopDefault(import('../pages/erp/vendors/Create.vue' /* webpackChunkName: "pages/erp/vendors/Create" */))
const _7b1dc42c = () => interopDefault(import('../pages/erp/vendors/detail.vue' /* webpackChunkName: "pages/erp/vendors/detail" */))
const _b8b834de = () => interopDefault(import('../pages/erp/vendors/filter.vue' /* webpackChunkName: "pages/erp/vendors/filter" */))
const _7753a608 = () => interopDefault(import('../pages/erp/items/inventory/detail.vue' /* webpackChunkName: "pages/erp/items/inventory/detail" */))
const _58866daf = () => interopDefault(import('../pages/erp/items/inventory/filter.vue' /* webpackChunkName: "pages/erp/items/inventory/filter" */))
const _26c39cb8 = () => interopDefault(import('../pages/erp/items/item/Create.vue' /* webpackChunkName: "pages/erp/items/item/Create" */))
const _3329cb4e = () => interopDefault(import('../pages/erp/items/item/detail.vue' /* webpackChunkName: "pages/erp/items/item/detail" */))
const _70c43c00 = () => interopDefault(import('../pages/erp/items/item/filter.vue' /* webpackChunkName: "pages/erp/items/item/filter" */))
const _44850a1e = () => interopDefault(import('../pages/erp/items/location/filter.vue' /* webpackChunkName: "pages/erp/items/location/filter" */))
const _6d0b8138 = () => interopDefault(import('../pages/erp/purchase/Procurement/Create.vue' /* webpackChunkName: "pages/erp/purchase/Procurement/Create" */))
const _7971afce = () => interopDefault(import('../pages/erp/purchase/Procurement/detail.vue' /* webpackChunkName: "pages/erp/purchase/Procurement/detail" */))
const _b70c2080 = () => interopDefault(import('../pages/erp/purchase/Procurement/filter.vue' /* webpackChunkName: "pages/erp/purchase/Procurement/filter" */))
const _2f130dd1 = () => interopDefault(import('../pages/erp/purchase/TransferOrder/Create.vue' /* webpackChunkName: "pages/erp/purchase/TransferOrder/Create" */))
const _c09c5ab4 = () => interopDefault(import('../pages/erp/purchase/TransferOrder/Detail.vue' /* webpackChunkName: "pages/erp/purchase/TransferOrder/Detail" */))
const _0a12be2d = () => interopDefault(import('../pages/erp/purchase/TransferOrder/filter.vue' /* webpackChunkName: "pages/erp/purchase/TransferOrder/filter" */))
const _b3eb08a8 = () => interopDefault(import('../pages/erp/report/inventory/details/index.vue' /* webpackChunkName: "pages/erp/report/inventory/details/index" */))
const _2ce193f0 = () => interopDefault(import('../pages/erp/report/inventory/locations/index.vue' /* webpackChunkName: "pages/erp/report/inventory/locations/index" */))
const _e9a2ec64 = () => interopDefault(import('../pages/erp/report/inventory/lowStock/index.vue' /* webpackChunkName: "pages/erp/report/inventory/lowStock/index" */))
const _b034d238 = () => interopDefault(import('../pages/erp/report/inventory/priceChangesHistory/index.vue' /* webpackChunkName: "pages/erp/report/inventory/priceChangesHistory/index" */))
const _87a02948 = () => interopDefault(import('../pages/erp/report/inventory/stockSummary/index.vue' /* webpackChunkName: "pages/erp/report/inventory/stockSummary/index" */))
const _193b8004 = () => interopDefault(import('../pages/erp/report/inventory/valuations/index.vue' /* webpackChunkName: "pages/erp/report/inventory/valuations/index" */))
const _3f362e1c = () => interopDefault(import('../pages/erp/report/purchase/bill/index.vue' /* webpackChunkName: "pages/erp/report/purchase/bill/index" */))
const _37e5136a = () => interopDefault(import('../pages/erp/report/purchase/items/index.vue' /* webpackChunkName: "pages/erp/report/purchase/items/index" */))
const _6cd355f4 = () => interopDefault(import('../pages/erp/report/purchase/paymentMade/index.vue' /* webpackChunkName: "pages/erp/report/purchase/paymentMade/index" */))
const _9635ae1a = () => interopDefault(import('../pages/erp/report/purchase/vendor/index.vue' /* webpackChunkName: "pages/erp/report/purchase/vendor/index" */))
const _5e742dff = () => interopDefault(import('../pages/erp/report/purchase/vendorBalance/index.vue' /* webpackChunkName: "pages/erp/report/purchase/vendorBalance/index" */))
const _8ad9d0b0 = () => interopDefault(import('../pages/erp/report/sales/customerSales/index.vue' /* webpackChunkName: "pages/erp/report/sales/customerSales/index" */))
const _9bb8b95a = () => interopDefault(import('../pages/erp/report/sales/itemSales/index.vue' /* webpackChunkName: "pages/erp/report/sales/itemSales/index" */))
const _71f7221e = () => interopDefault(import('../pages/erp/report/sales/locationSales/index.vue' /* webpackChunkName: "pages/erp/report/sales/locationSales/index" */))
const _1bc2b152 = () => interopDefault(import('../pages/erp/report/sales/salesPaymentMethod/index.vue' /* webpackChunkName: "pages/erp/report/sales/salesPaymentMethod/index" */))
const _1c909b5b = () => interopDefault(import('../pages/erp/report/sales/salesPerson/index.vue' /* webpackChunkName: "pages/erp/report/sales/salesPerson/index" */))
const _d66a5de4 = () => interopDefault(import('../pages/erp/report/inventory/details/filter.vue' /* webpackChunkName: "pages/erp/report/inventory/details/filter" */))
const _7c453b9c = () => interopDefault(import('../pages/erp/report/inventory/locations/filter.vue' /* webpackChunkName: "pages/erp/report/inventory/locations/filter" */))
const _5428872c = () => interopDefault(import('../pages/erp/report/inventory/lowStock/filter.vue' /* webpackChunkName: "pages/erp/report/inventory/lowStock/filter" */))
const _6359c654 = () => interopDefault(import('../pages/erp/report/inventory/priceChangesHistory/filter.vue' /* webpackChunkName: "pages/erp/report/inventory/priceChangesHistory/filter" */))
const _4353575e = () => interopDefault(import('../pages/erp/report/inventory/stockSummary/filter.vue' /* webpackChunkName: "pages/erp/report/inventory/stockSummary/filter" */))
const _1b28d208 = () => interopDefault(import('../pages/erp/report/inventory/valuations/filter.vue' /* webpackChunkName: "pages/erp/report/inventory/valuations/filter" */))
const _b483e6f0 = () => interopDefault(import('../pages/erp/report/purchase/bill/filter.vue' /* webpackChunkName: "pages/erp/report/purchase/bill/filter" */))
const _17272a4f = () => interopDefault(import('../pages/erp/report/purchase/items/filter.vue' /* webpackChunkName: "pages/erp/report/purchase/items/filter" */))
const _3a8bba18 = () => interopDefault(import('../pages/erp/report/purchase/paymentMade/filter.vue' /* webpackChunkName: "pages/erp/report/purchase/paymentMade/filter" */))
const _6145cca7 = () => interopDefault(import('../pages/erp/report/purchase/vendor/filter.vue' /* webpackChunkName: "pages/erp/report/purchase/vendor/filter" */))
const _2cd12dca = () => interopDefault(import('../pages/erp/report/purchase/vendorBalance/filter.vue' /* webpackChunkName: "pages/erp/report/purchase/vendorBalance/filter" */))
const _3022eceb = () => interopDefault(import('../pages/erp/report/sales/customerSales/detail.vue' /* webpackChunkName: "pages/erp/report/sales/customerSales/detail" */))
const _1155b492 = () => interopDefault(import('../pages/erp/report/sales/customerSales/filter.vue' /* webpackChunkName: "pages/erp/report/sales/customerSales/filter" */))
const _0bd69e47 = () => interopDefault(import('../pages/erp/report/sales/itemSales/filter.vue' /* webpackChunkName: "pages/erp/report/sales/itemSales/filter" */))
const _d9e1732e = () => interopDefault(import('../pages/erp/report/sales/locationSales/filter.vue' /* webpackChunkName: "pages/erp/report/sales/locationSales/filter" */))
const _6987ca7a = () => interopDefault(import('../pages/erp/report/sales/salesPaymentMethod/filter.vue' /* webpackChunkName: "pages/erp/report/sales/salesPaymentMethod/filter" */))
const _21eeb182 = () => interopDefault(import('../pages/erp/report/sales/salesPerson/filter.vue' /* webpackChunkName: "pages/erp/report/sales/salesPerson/filter" */))
const _7adae04e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _ba4a95d8,
    name: "about"
  }, {
    path: "/erp",
    component: _62876ead,
    name: "erp"
  }, {
    path: "/Login",
    component: _03ac52d0,
    name: "Login"
  }, {
    path: "/erp/dashboard",
    component: _7514bd32,
    name: "erp-dashboard"
  }, {
    path: "/erp/mixins",
    component: _56d69e30,
    name: "erp-mixins"
  }, {
    path: "/erp/orders",
    component: _1e9ebbc6,
    name: "erp-orders"
  }, {
    path: "/erp/PopUpSearch",
    component: _a6d977e2,
    name: "erp-PopUpSearch"
  }, {
    path: "/erp/purchase",
    component: _acafa40e,
    name: "erp-purchase"
  }, {
    path: "/erp/report",
    component: _fcab84a8,
    name: "erp-report"
  }, {
    path: "/erp/utils",
    component: _2809bf03,
    name: "erp-utils"
  }, {
    path: "/erp/vendors",
    component: _81696a6e,
    name: "erp-vendors"
  }, {
    path: "/erp/dashboard/filter",
    component: _b1ee7f70,
    name: "erp-dashboard-filter"
  }, {
    path: "/erp/items/adjustment",
    component: _40fb9554,
    name: "erp-items-adjustment"
  }, {
    path: "/erp/items/inventory",
    component: _620846eb,
    name: "erp-items-inventory"
  }, {
    path: "/erp/items/item",
    component: _5e0ef70c,
    name: "erp-items-item"
  }, {
    path: "/erp/items/location",
    component: _489ce09c,
    name: "erp-items-location"
  }, {
    path: "/erp/orders/detail",
    component: _84929dd4,
    name: "erp-orders-detail"
  }, {
    path: "/erp/orders/filter",
    component: _c22d0e86,
    name: "erp-orders-filter"
  }, {
    path: "/erp/purchase/AddItem",
    component: _3ebc239b,
    name: "erp-purchase-AddItem"
  }, {
    path: "/erp/purchase/Create",
    component: _29e3b805,
    name: "erp-purchase-Create"
  }, {
    path: "/erp/purchase/detail",
    component: _23b0a0ba,
    name: "erp-purchase-detail"
  }, {
    path: "/erp/purchase/Edit",
    component: _75b7935a,
    name: "erp-purchase-Edit"
  }, {
    path: "/erp/purchase/filter",
    component: _04e36861,
    name: "erp-purchase-filter"
  }, {
    path: "/erp/purchase/GRNDetail",
    component: _4b24ff9b,
    name: "erp-purchase-GRNDetail"
  }, {
    path: "/erp/purchase/Invoice",
    component: _0184ba18,
    name: "erp-purchase-Invoice"
  }, {
    path: "/erp/purchase/Payment",
    component: _52630566,
    name: "erp-purchase-Payment"
  }, {
    path: "/erp/purchase/Procurement",
    component: _f4f8828c,
    name: "erp-purchase-Procurement"
  }, {
    path: "/erp/purchase/SearchTax",
    component: _39de85ea,
    name: "erp-purchase-SearchTax"
  }, {
    path: "/erp/purchase/TransferOrder",
    component: _bcde22a6,
    name: "erp-purchase-TransferOrder"
  }, {
    path: "/erp/vendors/Create",
    component: _6eb79596,
    name: "erp-vendors-Create"
  }, {
    path: "/erp/vendors/detail",
    component: _7b1dc42c,
    name: "erp-vendors-detail"
  }, {
    path: "/erp/vendors/filter",
    component: _b8b834de,
    name: "erp-vendors-filter"
  }, {
    path: "/erp/items/inventory/detail",
    component: _7753a608,
    name: "erp-items-inventory-detail"
  }, {
    path: "/erp/items/inventory/filter",
    component: _58866daf,
    name: "erp-items-inventory-filter"
  }, {
    path: "/erp/items/item/Create",
    component: _26c39cb8,
    name: "erp-items-item-Create"
  }, {
    path: "/erp/items/item/detail",
    component: _3329cb4e,
    name: "erp-items-item-detail"
  }, {
    path: "/erp/items/item/filter",
    component: _70c43c00,
    name: "erp-items-item-filter"
  }, {
    path: "/erp/items/location/filter",
    component: _44850a1e,
    name: "erp-items-location-filter"
  }, {
    path: "/erp/purchase/Procurement/Create",
    component: _6d0b8138,
    name: "erp-purchase-Procurement-Create"
  }, {
    path: "/erp/purchase/Procurement/detail",
    component: _7971afce,
    name: "erp-purchase-Procurement-detail"
  }, {
    path: "/erp/purchase/Procurement/filter",
    component: _b70c2080,
    name: "erp-purchase-Procurement-filter"
  }, {
    path: "/erp/purchase/TransferOrder/Create",
    component: _2f130dd1,
    name: "erp-purchase-TransferOrder-Create"
  }, {
    path: "/erp/purchase/TransferOrder/Detail",
    component: _c09c5ab4,
    name: "erp-purchase-TransferOrder-Detail"
  }, {
    path: "/erp/purchase/TransferOrder/filter",
    component: _0a12be2d,
    name: "erp-purchase-TransferOrder-filter"
  }, {
    path: "/erp/report/inventory/details",
    component: _b3eb08a8,
    name: "erp-report-inventory-details"
  }, {
    path: "/erp/report/inventory/locations",
    component: _2ce193f0,
    name: "erp-report-inventory-locations"
  }, {
    path: "/erp/report/inventory/lowStock",
    component: _e9a2ec64,
    name: "erp-report-inventory-lowStock"
  }, {
    path: "/erp/report/inventory/priceChangesHistory",
    component: _b034d238,
    name: "erp-report-inventory-priceChangesHistory"
  }, {
    path: "/erp/report/inventory/stockSummary",
    component: _87a02948,
    name: "erp-report-inventory-stockSummary"
  }, {
    path: "/erp/report/inventory/valuations",
    component: _193b8004,
    name: "erp-report-inventory-valuations"
  }, {
    path: "/erp/report/purchase/bill",
    component: _3f362e1c,
    name: "erp-report-purchase-bill"
  }, {
    path: "/erp/report/purchase/items",
    component: _37e5136a,
    name: "erp-report-purchase-items"
  }, {
    path: "/erp/report/purchase/paymentMade",
    component: _6cd355f4,
    name: "erp-report-purchase-paymentMade"
  }, {
    path: "/erp/report/purchase/vendor",
    component: _9635ae1a,
    name: "erp-report-purchase-vendor"
  }, {
    path: "/erp/report/purchase/vendorBalance",
    component: _5e742dff,
    name: "erp-report-purchase-vendorBalance"
  }, {
    path: "/erp/report/sales/customerSales",
    component: _8ad9d0b0,
    name: "erp-report-sales-customerSales"
  }, {
    path: "/erp/report/sales/itemSales",
    component: _9bb8b95a,
    name: "erp-report-sales-itemSales"
  }, {
    path: "/erp/report/sales/locationSales",
    component: _71f7221e,
    name: "erp-report-sales-locationSales"
  }, {
    path: "/erp/report/sales/salesPaymentMethod",
    component: _1bc2b152,
    name: "erp-report-sales-salesPaymentMethod"
  }, {
    path: "/erp/report/sales/salesPerson",
    component: _1c909b5b,
    name: "erp-report-sales-salesPerson"
  }, {
    path: "/erp/report/inventory/details/filter",
    component: _d66a5de4,
    name: "erp-report-inventory-details-filter"
  }, {
    path: "/erp/report/inventory/locations/filter",
    component: _7c453b9c,
    name: "erp-report-inventory-locations-filter"
  }, {
    path: "/erp/report/inventory/lowStock/filter",
    component: _5428872c,
    name: "erp-report-inventory-lowStock-filter"
  }, {
    path: "/erp/report/inventory/priceChangesHistory/filter",
    component: _6359c654,
    name: "erp-report-inventory-priceChangesHistory-filter"
  }, {
    path: "/erp/report/inventory/stockSummary/filter",
    component: _4353575e,
    name: "erp-report-inventory-stockSummary-filter"
  }, {
    path: "/erp/report/inventory/valuations/filter",
    component: _1b28d208,
    name: "erp-report-inventory-valuations-filter"
  }, {
    path: "/erp/report/purchase/bill/filter",
    component: _b483e6f0,
    name: "erp-report-purchase-bill-filter"
  }, {
    path: "/erp/report/purchase/items/filter",
    component: _17272a4f,
    name: "erp-report-purchase-items-filter"
  }, {
    path: "/erp/report/purchase/paymentMade/filter",
    component: _3a8bba18,
    name: "erp-report-purchase-paymentMade-filter"
  }, {
    path: "/erp/report/purchase/vendor/filter",
    component: _6145cca7,
    name: "erp-report-purchase-vendor-filter"
  }, {
    path: "/erp/report/purchase/vendorBalance/filter",
    component: _2cd12dca,
    name: "erp-report-purchase-vendorBalance-filter"
  }, {
    path: "/erp/report/sales/customerSales/detail",
    component: _3022eceb,
    name: "erp-report-sales-customerSales-detail"
  }, {
    path: "/erp/report/sales/customerSales/filter",
    component: _1155b492,
    name: "erp-report-sales-customerSales-filter"
  }, {
    path: "/erp/report/sales/itemSales/filter",
    component: _0bd69e47,
    name: "erp-report-sales-itemSales-filter"
  }, {
    path: "/erp/report/sales/locationSales/filter",
    component: _d9e1732e,
    name: "erp-report-sales-locationSales-filter"
  }, {
    path: "/erp/report/sales/salesPaymentMethod/filter",
    component: _6987ca7a,
    name: "erp-report-sales-salesPaymentMethod-filter"
  }, {
    path: "/erp/report/sales/salesPerson/filter",
    component: _21eeb182,
    name: "erp-report-sales-salesPerson-filter"
  }, {
    path: "/",
    component: _7adae04e,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
