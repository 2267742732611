// eslint-disable-next-line no-empty-pattern
export default ({}, inject) => {
  if (typeof window.android === 'object') {
    inject('bridge', window.android)
  } else {
    inject('bridge', {
      setLocalStorage (key, value) {
        return null
      },
      getLocalStorage (key) {
        return null
      },
      removeLocalStorage (key) {
        return null
      },
      clearLocalStorage () {
        return null
      },
      getUUID () {
        return 'UUIDTESTING#v1-web'
      },
      getLatLong (test) {
        const emitHomeLastLocation = window.emitHomeLastLocation
        setTimeout(() => {
          emitHomeLastLocation(JSON.stringify({
            lat: 14.038972,
            lng: 80.251486
          }))
        }, 2000)
      },
      stopLocationTracking () {
        return null
      },
      backPress () {
        console.log('[mock bridge] triggering backPress ')
        return null
      },
      getUploadFileName (type, fileName, size) {
        return {
          name: 'demo-name'
        }
      },
      uploadInvoice (url) {
        // return the image name
        return null
      },
      getApplicationDetails () {
        return JSON.stringify({
          version: '1.0.0',
          type: 'version',
          version_code: 1
        })
      },
      googleMapDirection (lat, lng) {
        return null
      },
      openBrowser (link) {
        window.open(link)
      }
    })
  }
}
